import * as React from 'react';
import {Button, Row, Col} from 'react-bootstrap';

import styles from 'local_modules/styles/chooser.css';

export interface App {
  readonly label: string;
  readonly url: string;
}

export interface AppGroup {
  readonly label: string;
  readonly apps: App[];
}

export interface Props {
  readonly foodhubName: string;
  readonly appGroups: AppGroup[];
}

const AppChooser: React.SFC<Props> = ({
  foodhubName,
  appGroups,
}: Props): React.ReactElement<Props> => (
  <>
    <div>
      <h1>Welcome to {foodhubName}!</h1>
      <p className={styles.instructions}>What are you working on? Pick an app to get started.</p>
    </div>
    {appGroups.map(({label, apps}) => (
      <React.Fragment key={label}>
        <h2 className={styles.groupHeading}>{label}</h2>
        <Row className={styles.row}>
          {apps.map((app) => (
            <Col className={styles.col} sm={4} key={app.label}>
              <Button block bsSize="large" href={app.url} className={styles.button}>
                {app.label}
              </Button>
            </Col>
          ))}
        </Row>
      </React.Fragment>
    ))}
  </>
);

export default AppChooser;
