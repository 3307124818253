import * as React from 'react';
import foodhubs from 'goodeggs-foodhubs';
import {Button, Row, Col} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

import styles from 'local_modules/styles/chooser.css';

export interface Props {}

const FoodhubChooser: React.SFC<Props> = () => (
  <>
    <div>
      <h1>Where are you?</h1>
      <p className={styles.instructions}>Select a foodhub to get started.</p>
    </div>
    <h2 className={styles.groupHeading}>Foodhubs</h2>
    <Row className={styles.row}>
      {foodhubs.all.map((foodhub) => (
        <Col className={styles.col} sm={4} key={foodhub.slug}>
          <LinkContainer to={`/${foodhub.slug}`}>
            <Button block bsSize="large" className={styles.button}>
              {foodhub.name}
            </Button>
          </LinkContainer>
        </Col>
      ))}
    </Row>
  </>
);

export default FoodhubChooser;
