import {Action as ReduxAction} from 'redux';

import setFoodhubSlug, {
  SET_FOODHUB_SLUG,
  SetFoodhubSlugAction,
} from './action_creator_set_foodhub_slug';
import initialState from './initial_state';

export type State = string | null;

export interface InitAction extends ReduxAction {
  readonly type: '@@INIT';
}

export type Action = InitAction | SetFoodhubSlugAction;

export default function foodhubSlugReducer(
  previousState: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case SET_FOODHUB_SLUG:
      return action.payload;
    default:
      return previousState;
  }
}

export {setFoodhubSlug};
