import {connect} from 'react-redux';
import foodhubs from 'goodeggs-foodhubs';
import invariant from 'invariant';

import {State} from 'local_modules/store';

import AppChooser, {AppGroup} from './app_chooser';
import getAppGroups from './get_app_groups';

export interface StateProps {
  readonly foodhubName: string;
  readonly appGroups: AppGroup[];
}

export interface OwnProps {
  readonly foodhubSlug: string;
}

export type Props = StateProps & OwnProps;

const mapStateToProps = ({auth: {credentials}}: State, {foodhubSlug}: OwnProps): StateProps => {
  const foodhub = foodhubs.bySlug(foodhubSlug);
  invariant(foodhub != null, 'foodhub is not defined');
  // TODO(serhalp) Add TS types to goodeggs-redux-authentication
  invariant(credentials != null, 'credentials is not defined');
  return {
    appGroups: getAppGroups({foodhubSlug, roles: credentials.employee.roles}),
    foodhubName: foodhub.name,
  };
};

export default connect(mapStateToProps)(AppChooser);
