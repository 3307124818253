import {State} from '..';

type SerializedState = {
  readonly foodhubSlug: string | null;
};

export function loadState(): Record<string, any> | undefined {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState == null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.warn('Error parsing state from local storage', {err});
    return undefined;
  }
}

export function saveState(state: State): void {
  try {
    const serializedState = serializeState(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    console.warn('Error saving state to local storage', {err});
  }
}

function serializeState(state: State): string {
  const serializedState: SerializedState = {
    foodhubSlug: state.foodhubSlug,
  };
  return JSON.stringify(serializedState);
}
