export interface Settings {
  env: 'development' | 'test' | 'production';
  name: string;
  appInstance: string;
  sha: string;
  useIpAddressForHttpRequests?: boolean;
  server?: {
    bind?: string;
  };
  rollbarClientAccessToken?: string;
}

const defaultSettings: Settings = {
  env: 'development',
  name: 'portal-ui',
  appInstance: 'localhost',
  sha: 'unknown',
};

let windowSettings = {};
if (window.settings != null) {
  windowSettings = window.settings;
}

const settings: Settings = {
  ...defaultSettings,
  ...windowSettings,
};

export default settings;

export const envSettings = {
  env: settings.env,
  appInstance: settings.appInstance,
  server: {
    bind: settings?.server?.bind,
  },
  useIpAddressForHttpRequests: settings.useIpAddressForHttpRequests,
};
