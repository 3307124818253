import * as React from 'react';
import {LinkContainer} from 'react-router-bootstrap';

interface Props {
  location: Location;
}

const NoMatch: React.SFC<Props> = ({location}: Props): React.ReactElement<Props> => (
  <div className="text-center">
    <h3>
      No match for <code>{location.pathname}</code>.
    </h3>

    <LinkContainer to={`/choose_foodhub`}>
      <a>Return to the foodhub chooser</a>
    </LinkContainer>
  </div>
);

export default NoMatch;
