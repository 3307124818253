import {getUrl} from 'goodeggs-sites';
import employeeRoles from 'goodeggs-employee-roles';
import foodhubs from 'goodeggs-foodhubs';
import invariant from 'invariant';

import {envSettings} from 'local_modules/settings';

import {AppGroup} from './app_chooser';

const getAppGroups = ({foodhubSlug, roles}: {foodhubSlug: string; roles: string[]}): AppGroup[] => {
  const debugNetworkUrl = 'http://debug.goodeggs.com';
  const freshDeskUrl = 'https://goodeggs.freshdesk.com/support/home';
  const employeesUiUrl = getUrl('employees-ui', envSettings);
  const fulfillmentOptionsUiUrl = getUrl('fulfillment-options-ui', envSettings);
  const kaleUrl = getUrl('kale', envSettings);
  const riversandUiUrl = getUrl('riversand-ui', envSettings);
  const highJumpUiUrl = getUrl('highjump-ui', envSettings);
  // 'production', 'staging', and 'dev' are configured within the same Bringg app, which is why this is hardcoded as a single URL
  const bringgUrl = 'https://app.bringg.com';

  type AppGroupConfig = Array<{
    label: string;
    apps: Array<{
      label: string;
      url: string;
      permission: null | string;
      isFulfillmentCenterApp: boolean;
      isProductionCenterApp: boolean;
      /** @param foodhubSlugs If specified, only show this app for these foodhubs. This takes precedence over
         `isFulfillmentCenterApp` and `isProductionCenterApp`.
       */
      foodhubSlugs?: string[];
    }>;
  }>;

  const appGroupConfig: AppGroupConfig = [
    {
      label: 'Fulfillment',
      apps: [
        {
          label: 'HighJump WMS',
          url: highJumpUiUrl,
          permission: null,
          isFulfillmentCenterApp: true,
          isProductionCenterApp: true,
        },
        {
          label: 'Bringg',
          url: bringgUrl,
          permission: null, // everybody can see Bringg because temps use it
          isFulfillmentCenterApp: true,
          isProductionCenterApp: false,
        },
      ],
    },
    {
      label: 'Assortment',
      apps: [
        {
          label: 'Riversand',
          url: riversandUiUrl,
          permission: null,
          isFulfillmentCenterApp: true,
          isProductionCenterApp: true,
        },
      ],
    },
    {
      label: 'Community Care',
      apps: [
        {
          label: 'Shorts',
          url: `${kaleUrl}/#/hub/${foodhubSlug}/shorts`,
          permission: 'FIXME',
          isFulfillmentCenterApp: true,
          isProductionCenterApp: false,
        },
        {
          label: 'Customers',
          url: `${kaleUrl}/#/customers`,
          permission: 'FIXME',
          isFulfillmentCenterApp: true,
          isProductionCenterApp: false,
        },
      ],
    },
    {
      label: 'Management',
      apps: [
        {
          label: 'Employees',
          url: employeesUiUrl,
          permission: 'employees', // wtf this isn't a permission
          isFulfillmentCenterApp: true,
          isProductionCenterApp: true,
        },
        {
          label: 'Foodhub Capacities',
          url: `${fulfillmentOptionsUiUrl}/#/${foodhubSlug}/capacities`,
          permission: 'capacities.show',
          isFulfillmentCenterApp: true,
          isProductionCenterApp: false,
        },
      ],
    },
    {
      label: 'Troubleshooting',
      apps: [
        {
          label: 'Debug Network',
          url: debugNetworkUrl,
          permission: null,
          isFulfillmentCenterApp: true,
          isProductionCenterApp: true,
        },
        {
          label: 'Get Help',
          url: freshDeskUrl,
          permission: null,
          isFulfillmentCenterApp: true,
          isProductionCenterApp: true,
        },
      ],
    },
  ];

  return appGroupConfig
    .map(({label, apps}) => ({
      label,
      apps: apps
        .filter(({permission, isFulfillmentCenterApp, isProductionCenterApp, foodhubSlugs}) => {
          const foodhub = foodhubs.bySlug(foodhubSlug);
          invariant(foodhub, 'foodhub is not defined');
          const userHasPermission =
            permission === null ||
            // "FIXME" permission means we currently show the app to everyone who has any any role
            // at all (i.e. non temp drivers). We should flesh out roles and permissions to eliminate these.
            (permission === 'FIXME' && roles.length > 0) ||
            (employeeRoles.can(roles, permission) as boolean);
          const showForFoodhub =
            foodhubSlugs != null
              ? foodhubSlugs.includes(foodhubSlug)
              : (foodhub.isFulfillmentCenter && isFulfillmentCenterApp) ||
                (foodhub.isProductionCenter && isProductionCenterApp);
          return userHasPermission && showForFoodhub;
        })
        .map((app) => ({label: app.label, url: app.url})),
    }))
    .filter(({apps}) => apps.length > 0); // remove app groups where the user can access zero apps
};

export default getAppGroups;
