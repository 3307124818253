import {getUrl} from 'goodeggs-sites';
import {createAuthenticateMiddleware} from 'goodeggs-redux-authentication';

import {envSettings} from 'local_modules/settings';

const kaleUrl = getUrl('kale', envSettings);
const opsEmployeesApi = getUrl('ops-employees-api', envSettings);

export default createAuthenticateMiddleware({
  // NOTE: DO NOT COPY THIS TO OTHER APPS. This app specially needs to allow non-employees, so
  // instead of hitting the SAML (`/auth/google?redirect=...`) endpoint, we go to the UI login page
  // where users can say whether they are an employee or not.
  getProvideTokenUrl: () => `${kaleUrl}/#/login`,
  identifyTokenUrl: `${opsEmployeesApi}/v1/auth/token/identify/google`,
  renewTokenUrl: `${kaleUrl}/auth/token/google/renew`,
  shouldIdentifySegmentUser: false,
  allowNonEmployees: true,
});
