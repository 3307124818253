import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

// Style order matters here: these styles must be loaded after bootstrap styles
import 'goodeggs-ui-components/build/styles.css';
import 'local_modules/styles/globals.css';

import React from 'react';
import {render} from 'react-dom';
import {
  addErrorMessage,
  configureRollbar,
  getUserErrorMessage,
} from 'goodeggs-ops-ui-error-helpers';

import settings from 'local_modules/settings';
import store from 'local_modules/store';

import App from './app';

configureRollbar({
  rollbar: window.Rollbar,
  simulate: settings.rollbarClientAccessToken == null,
  onError: (err) => store.dispatch(addErrorMessage(getUserErrorMessage(err))),
  getExtraContext: () => {
    const {credentials} = store.getState().auth;
    if (credentials != null && credentials.employee != null) {
      const {employee} = credentials;
      return {
        person: {
          id: employee._id,
          email: employee.email,
          username: employee.fullName,
        },
      };
    }
    return {};
  },
});

const mountNodeId = 'root';
const mountNode = document.getElementById(mountNodeId);
if (mountNode == null) {
  throw new Error(
    `Cannot render app because no element with id #${mountNodeId} was found. Check your HTML document to ensure this node exists.`,
  );
}

render(<App />, mountNode);
