import * as React from 'react';

import AppContainer from './app_container';

export type Props = {
  children?: React.ReactNode;
  foodhubSlug: string | null;
};

const Page: React.SFC<Props> = (props: Props) => {
  const {children, foodhubSlug} = props;
  return <AppContainer foodhubSlug={foodhubSlug}>{children}</AppContainer>;
};

export default Page;
