import thunk from 'redux-thunk';
import {applyMiddleware, createStore, Store} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {routerMiddleware} from 'react-router-redux';
import {ErrorMessageAction, ErrorMessagesState} from 'goodeggs-ops-ui-error-helpers';
import {State as AuthState, Credentials} from 'goodeggs-redux-authentication';

import history from 'local_modules/history';
import {
  State as FoodhubSlugState,
  Action as FoodhubSlugAction,
} from 'local_modules/redux_foodhub_slug';

import authenticationMiddleware from './middleware_authentication';
import reducer from './reducer';
import {loadState, saveState} from './local_storage';

export type RoutingLocationState = {
  readonly pathname: string;
};

export type RoutingState = {
  readonly location: RoutingLocationState | null;
};

export {AuthState, Credentials};

export type State = {
  readonly auth: AuthState;
  readonly errorMessages: ErrorMessagesState;
  readonly routing: RoutingState;
  readonly foodhubSlug: FoodhubSlugState;
};

export type Action = ErrorMessageAction | FoodhubSlugAction;
export type Dispatch = (action: Action | ThunkAction | PromiseAction) => any;
export type GetState = () => State;
export type PromiseAction = Promise<Action>;
export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;

const middleware = [authenticationMiddleware, routerMiddleware(history), thunk];

const store: Store<State, Action> = createStore(
  reducer,
  loadState(),
  // http://extension.remotedev.io/#usage
  composeWithDevTools(applyMiddleware(...middleware)),
);

store.subscribe(() => {
  const state = store.getState();
  saveState(state);
});

export default store;
