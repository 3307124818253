import * as React from 'react';
import foodhubs from 'goodeggs-foodhubs';
import {ConnectedRouter} from 'react-router-redux';
import {Provider} from 'react-redux';
import {Redirect, Route, Switch, RouteComponentProps} from 'react-router';
import {hot} from 'react-hot-loader';

import history from 'local_modules/history';
import store from 'local_modules/store';
import {setFoodhubSlug} from 'local_modules/redux_foodhub_slug';

import ConnectedAppChooser from './connected_app_chooser';
import FoodhubChooser from './foodhub_chooser';
import NoMatch from './no_match';
import Page from './page';

class FoodhubRoute extends React.Component<RouteComponentProps<{foodhubSlug: string}>> {
  public UNSAFE_componentWillMount(): void {
    store.dispatch(setFoodhubSlug(this.props.match.params.foodhubSlug));
  }

  public UNSAFE_componentWillReceiveProps(newProps): void {
    store.dispatch(setFoodhubSlug(newProps.match.params.foodhubSlug));
  }

  public render() {
    const {foodhubSlug} = this.props.match.params;
    return (
      <Page {...this.props} foodhubSlug={foodhubSlug}>
        <ConnectedAppChooser foodhubSlug={foodhubSlug} />
      </Page>
    );
  }
}

const App = (): React.ReactElement<unknown> => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            const {foodhubSlug} = store.getState();
            return <Redirect to={`/${foodhubSlug != null ? foodhubSlug : 'choose_foodhub'}`} />;
          }}
        />
        <Route
          exact
          path="/choose_foodhub"
          render={(props) => (
            <Page {...props} foodhubSlug={null}>
              <FoodhubChooser />
            </Page>
          )}
        />
        <Route
          path={`/:foodhubSlug(${foodhubs.foodhubSlugs.join('|')})`}
          render={(props) => <FoodhubRoute {...props} />}
        />
        <Route component={NoMatch} />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

// NOTE: Marking the root component as hot must happen in its own file; it can't be in the same file
// as the react-dom `mount()` call or hot reloading will break.
export default hot(module)(App);
