import {authenticationReducer} from 'goodeggs-redux-authentication';
import {combineReducers} from 'redux';
import {reducer as errorMessagesReducer} from 'goodeggs-ops-ui-error-helpers';
import {routerReducer} from 'react-router-redux';

import foodhubSlugReducer from 'local_modules/redux_foodhub_slug';

export default combineReducers({
  auth: authenticationReducer,
  errorMessages: errorMessagesReducer,
  routing: routerReducer,
  foodhubSlug: foodhubSlugReducer,
});
