import foodhubs, {Foodhub} from 'goodeggs-foodhubs';
import {AppContainer} from 'goodeggs-ui-components';
import {clearErrorMessage} from 'goodeggs-ops-ui-error-helpers';
import {connect} from 'react-redux';
import {getUrl} from 'goodeggs-sites';

import settings, {envSettings} from 'local_modules/settings';
import {State, Dispatch} from 'local_modules/store';

// TODO(ndhoule): Use exported AppContainer props?
export interface StateProps {
  appTitle: string;
  errors: string[];
  foodhub: Foodhub | null;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  kaleUrl: string;
  portalUrl: string;
  redirectUrl: string;
  sha: string;
  // eslint-disable-next-line @typescript-eslint/ban-types -- TODO(serhalp) Add TS types to goodeggs-redux-authentication
  user: object | null;
}

export interface DispatchProps {
  onDismissError: (e: string, i: number) => unknown;
}

export interface OwnProps {
  readonly foodhubSlug: string | null;
}

export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  appTitle: 'Portal',
  errors: state.errorMessages ?? [],
  foodhub: ownProps.foodhubSlug != null ? foodhubs.bySlug(ownProps.foodhubSlug) : null,
  isAuthenticated: state.auth.credentials != null,
  isAuthenticating: state.auth.isFetching || false,
  kaleUrl: getUrl('kale', envSettings),
  portalUrl: `${getUrl('portal-ui', envSettings)}/#/choose_foodhub`, // NOTE: don't copy this in other apps!
  redirectUrl: window.location.toString(),
  sha: settings.sha,
  user: state.auth.credentials != null ? state.auth.credentials : null,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onDismissError: (e, i) => dispatch(clearErrorMessage(i)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
