import {createAction} from 'redux-actions';
import {Action} from 'redux';

export const SET_FOODHUB_SLUG = 'foodhubSlug/SET_FOODHUB_SLUG';

// FIXME This isn't quite right... there's no way to do this the way we're used to in flow with the DefinitelyTyped libdef... :/
export interface SetFoodhubSlugAction extends Action {
  readonly type: 'foodhubSlug/SET_FOODHUB_SLUG';
  readonly payload: string;
}

export default function setFoodhubSlug(foodhubSlug: string): SetFoodhubSlugAction {
  // @ts-ignore(@serhalp): we'll have to figure this out eventually, but I'm giving up for now.
  return createAction(SET_FOODHUB_SLUG)(foodhubSlug);
}
